<template>
  <div class="channel-card">
    <div class="title-data">
      <span>渠道设置</span>
    </div>
    <div class="channel-main">
      <div class="search-data">
        <vh-button size="medium" class="length104" type="primary" @click="createChannel()" round>
          创建渠道
        </vh-button>
        <vh-button
          size="medium"
          class="head-btn length104 transparent-btn"
          round
          plain
          @click="allDelete(null)"
          :disabled="!channel_ids.length"
        >
          批量删除
        </vh-button>
        <vh-input
          round
          class="search-tag"
          placeholder="请输入渠道名称/渠道参数"
          v-model="keyword"
          clearable
          @clear="initQueryUserList"
          @keyup.enter.native="initQueryUserList"
        >
          <i class="vh-icon-search vh-input__icon" slot="prefix" @click="initQueryUserList"></i>
        </vh-input>
      </div>
      <div class="channel-card-list">
        <div class="list-table-panel" v-if="tableList.length != 0">
          <!-- 表格与分页 -->
          <vh-table
            ref="tableList"
            :data="tableList || []"
            @selection-change="handleTableCheckbox"
            :header-cell-style="{
              color: '#666',
              height: '56px'
            }"
          >
            <vh-table-column type="selection" :selectable="selectableFuc" width="60" align="left" />
            <vh-table-column
              align="left"
              v-for="(item, index) in baseTableColumn"
              :key="index"
              :width="item.width"
              :label="item.label"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <span :class="item.key">
                  <template v-if="item.key == 'channel_name'">
                    <span>{{ scope.row[item.key] }}</span>
                    <vh-tag class="tip" size="mini" v-show="scope.row.channel_type == 0">
                      默认
                    </vh-tag>
                  </template>
                  <template v-else-if="item.key == 'updated_at'">
                    <div v-if="scope.row.channel_type != 0 && scope.row.channel_type != 1">
                      <p class="eps">{{ scope.row.last_operator_nickname }}</p>
                      <p>{{ scope.row[item.key] }}</p>
                    </div>
                  </template>
                  <template v-else>{{ scope.row[item.key] }}</template>
                </span>
              </template>
            </vh-table-column>
            <vh-table-column label="操作" align="left" width="200px">
              <template slot-scope="scope">
                <span
                  class="tool"
                  v-show="scope.row.channel_type != 0 && scope.row.channel_type != 1"
                  @click="editChannel(scope.row)"
                >
                  编辑
                </span>
                <span
                  class="tool"
                  v-show="scope.row.channel_type != 0 && scope.row.channel_type != 1"
                  @click="deleteChannel(scope.row)"
                >
                  删除
                </span>
              </template>
            </vh-table-column>
          </vh-table>
          <SPagination
            :total="total || 0"
            :page-size="limit"
            :currentPage="pageNumber"
            @current-change="currentChangeHandler"
            @size-change="handleSizeChange"
          ></SPagination>
        </div>
        <!-- 无消息内容 -->
        <null-page class="search-no-data" v-else></null-page>
      </div>
    </div>

    <VhallDialog
      :title="channel.id ? '编辑渠道' : '创建渠道'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      custom-class="channel-dialog"
      width="468px"
    >
      <vh-form label-width="80px" :model="channel" ref="channelForm" :rules="rules">
        <vh-form-item label="渠道名称" prop="name">
          <vh-input
            v-model="channel.name"
            :maxlength="100"
            show-word-limit
            placeholder="请输入渠道名称"
            autocomplete="off"
          ></vh-input>
        </vh-form-item>
        <vh-form-item label="渠道参数" prop="code">
          <vh-input
            v-model="channel.code"
            :maxlength="100"
            show-word-limit
            placeholder="请输入渠道参数"
            autocomplete="off"
            :disabled="!!channel.id"
          ></vh-input>
        </vh-form-item>
      </vh-form>
      <div class="indent">
        建议渠道名称、参数结合投放的位置进行命名，方便理解与追踪；例如：微信公众号、wechat。渠道参数一旦创建不可修改。
      </div>
      <span slot="footer" class="dialog-footer create-footer">
        <vh-button type="info" size="medium" @click="dialogVisible = false" plain round>
          取消
        </vh-button>
        <vh-button type="primary" size="medium" v-preventReClick @click="saveChannelHandle" round>
          确定
        </vh-button>
      </span>
    </VhallDialog>
  </div>
</template>
<script>
  import NullPage from '../PlatformModule/Error/nullPage.vue';
  import regRule from '@/utils/reg-rule';
  export default {
    data() {
      const validCode = (rule, value, callback) => {
        if (value && !regRule['codePwd'].test(value)) {
          return callback(new Error('渠道参数不允许包含中文或特殊字符'));
        } else {
          callback();
        }
      };
      return {
        channel_ids: [],
        keyword: '',
        pos: 0,
        pageNumber: 1,
        limit: 20,
        total: 0,
        tableList: [],
        baseTableColumn: [
          {
            label: '名称',
            key: 'channel_name',
            width: 'auto'
          },
          {
            label: '参数',
            key: 'channel_code',
            width: 'auto'
          },
          {
            label: '最后操作',
            key: 'updated_at',
            width: 'auto'
          }
        ],
        dialogVisible: false,
        channel: {
          id: '',
          name: ''
        },
        rules: {
          name: [{ required: true, message: '请输入渠道名称', trigger: 'blur' }],
          code: [{ validator: validCode, trigger: 'blur' }]
        }
      };
    },
    components: {
      NullPage
    },
    created() {},
    mounted() {
      this.initQueryUserList();
    },
    methods: {
      selectableFuc(row) {
        return row.channel_type != 0 && row.channel_type != 1;
      },
      // 页码改变按钮事件
      currentChangeHandler(current) {
        this.pageNumber = current;
        this.pos = parseInt((current - 1) * this.limit);
        this.getChannelTableList();
      },
      // 每页显示个数选择器的选项设置 改变按钮事件
      handleSizeChange(current) {
        this.pageNumber = 1;
        this.limit = current;
        this.pos = 0;
        this.getChannelTableList();
      },
      // 复选框操作
      handleTableCheckbox(val) {
        console.log(val);
        this.channel_ids = val.map(item => item.id);
      },
      initQueryUserList() {
        this.pos = 0;
        this.pageNumber = 1;
        this.limit = 20;
        // 表格切换到第一页
        this.$nextTick(() => {
          if (this.$refs.tableList && this.$refs.tableList.pageInfo) {
            this.$refs.tableList.pageInfo.pageNum = 1;
            this.$refs.tableList.pageInfo.pos = 0;
          }
          this.getChannelTableList();
        });
      },
      getChannelTableList(row) {
        if (row) {
          this.pos = row.pos;
          this.pageNumber = row.pageNum;
        }
        this.loading = true;
        let params = {
          keyword: this.keyword,
          pos: this.pos,
          limit: this.limit
        };
        this.$fetch('getChannelList', this.$params(params), {
          'Content-Type': 'application/json'
        }).then(res => {
          // 有数据但是当前页没有
          if (
            res &&
            res.code === 200 &&
            res.data &&
            res.data.list.length == 0 &&
            res.data.total != 0
          ) {
            this.getChannelTableList({ pos: 0, pageNum: 1 });
            return;
          }

          this.total = res.data.total;
          this.tableList = res.data.list;
          console.log(this.tableList, '????????this.tableList');
        });
      },
      deleteChannel(rows) {
        this.deleteConfirm(rows.id);
      },
      deleteConfirm(id) {
        this.$vhConfirm('删除后，当前渠道将无法使用，确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,
          roundButton: true,

          cancelButtonClass: 'zdy-confirm-cancel'
        }).then(() => {
          this.$fetch(
            'deleteChannel',
            {
              channel_ids: id
            },
            {
              'Content-Type': 'application/json'
            }
          )
            .then(res => {
              if (res && res.code === 200) {
                this.$vhMessage({
                  message: `删除成功`,
                  showClose: true,
                  // duration: 0,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
                // 刷新页面
                this.$refs.tableList && this.$refs.tableList.clearSelection();
                this.getChannelTableList();
                this.channel_ids = [];
              }
            })
            .catch(res => {
              this.$vhMessage({
                message: res.msg || `删除失败`,
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            });
        });
      },
      allDelete(id) {
        id = this.channel_ids.join(',');
        this.deleteConfirm(id);
      },
      createChannel() {
        if (this.total > 200) {
          this.$vhMessage({
            message: '最多可以创建200个渠道',
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
        } else {
          this.channel.id = '';
          this.$set(this.channel, 'name', '');
          this.$set(this.channel, 'code', '');
          // this.$refs['channelForm'].resetFields();
          this.dialogVisible = true;
        }
      },
      editChannel(row) {
        this.channel.id = row.id;
        this.$set(this.channel, 'name', row.channel_name);
        this.$set(this.channel, 'code', row.channel_code);
        // this.$refs['channelForm'].resetFields();
        this.dialogVisible = true;
      },
      saveChannelHandle() {
        this.$refs.channelForm.validate(valid => {
          if (valid) {
            this.$fetch(
              'saveChannel',
              {
                channel_id: this.channel.id,
                channel_name: this.channel.name,
                channel_code: this.channel.code
              },
              {
                'Content-Type': 'application/json'
              }
            )
              .then(res => {
                if (res && res.code === 200) {
                  this.dialogVisible = false;
                  this.getChannelTableList();
                } else {
                  this.$vhMessage({
                    message: res.msg || `保存失败`,
                    showClose: true,
                    // duration: 0,
                    type: 'error',
                    customClass: 'zdy-info-box'
                  });
                }
              })
              .catch(res => {
                this.$vhMessage({
                  message: res.msg || `保存失败`,
                  showClose: true,
                  // duration: 0,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              });
          }
        });
      }
    }
  };
</script>
<style lang="less" scoped>
  .channel-card-list {
    background-color: #fff;
    padding: 24px;
    min-height: 676px;
  }
  .channel-card {
    .title-data {
      margin: 0 0 20px 0;
      text-align: left;
      line-height: 30px;
      span {
        font-size: 22px;
        font-weight: 600;
        color: #1a1a1a;
      }
      .subTitle {
        font-size: 14px;
        font-weight: 400;
        color: #666;
        margin-left: 12px;
      }
      .tool {
        color: #3562fa;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
      }
      .button-tip {
        vertical-align: top;
      }
    }
    .channel-main {
      .vh-card__body {
        padding: 32px 24px;
      }
      .search-data {
        margin-bottom: 30px;
      }

      .search-tag {
        float: right;
        width: 220px;
      }

      .list-table-panel {
        min-height: auto;
        .vh-table {
          .vh-table__body .cell {
            .webinar_num,
            .tool {
              color: #3562fa;
              cursor: pointer;
            }
          }
          .vh-table__row .tool {
            margin-right: 16px;
          }
          td:last-child,
          th:last-child {
            padding-right: 24px;
            text-align: right;
          }
          .tip {
            margin-left: 6px;
          }

          .eps {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .channel-dialog {
      .indent {
        padding-left: 80px;
        line-height: 1.5;
        color: #8c8c8c;
      }
      .vh-form-item {
        margin-bottom: 16px;
      }
    }
  }
  .no-live {
    .btn-list .vh-button {
      margin-right: 0;
    }
  }
</style>
<style lang="less">
  .channel-card {
    .vh-input--limit .vh-input__inner {
      padding-right: 58px;
    }
  }
  .vh-tooltip__popper {
    max-width: 450px;
  }
</style>
